<template>
  <div class="wrapper" v-if="profile">
    <div class="container">
      <div class="popular">
        <div class="games">
          <p>{{ $t("popularGames") }}</p>
          <div class="popular-games">
            <div
              v-for="(game, index) in homePageGame"
              v-bind:key="getKey(index)"
              class="crash-game fly-game"
            >
              <div class="game-icon" @click="openSlideUp(game)">
                <img
                  loading="lazy"
                  v-bind:src="game.image"
                  alt="Avatar"
                  data-toggle="modal"
                  v-bind:data-target="'#' + game.game_id"
                />
                <span>{{ game.game_name }}</span>
              </div>
              <!-- <div
                class="modal fade"
                v-bind:id="game.game_id"
                tabindex="-1"
                role="dialog"
                v-bind:aria-labelledby="game.game_id + 'Label'"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog" role="document">
                  <div
                    style="color: var(--darkBackground)"
                    class="modal-content"
                  >
                    <div class="modal-header">
                      <h5
                        class="modal-title"
                        v-bind:id="game.game_id + 'Label'"
                        v-text="game.game_name"
                      ></h5>
                      <span
                        type="button"
                        class="close"
                        id="faso-close"
                        data-dismiss="modal"
                        >&times;</span
                      >
                    </div>

                    <div class="modal-body">
                      <img
                        loading="lazy"
                        style="
                          border-radius: 5px;
                          border: solid var(--light-gray) 1px;
                          max-width: 500px;
                        "
                        v-bind:src="game.image"
                        alt="gameimg"
                        class="ww-100 mb-2"
                      />
                      <div class="text-center">
                        <router-link
                          data-dismiss="modal"
                          style="
                            background-color: var(--darkBackground);
                            color: var(--white);
                          "
                          class="btn ww-100 mb-2"
                          :to="{
                            name: 'aviator',
                            params: {
                              IsDemo: 0,
                              providerID: game.providerID,
                              game_name: game.game_name,
                              game_id: game.game_id,
                            },
                          }"
                        >
                          Play Now
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <ChopbetSlideUp
        :isOpen="isSlideUpOpen"
        @closeSlideup="closeSlideUp"
        :fullHeight="true"
      >
        <div class="chopslide">
          <div class="chopslide-heading" v-show="isLoading || isLoaded">
            <img src="/img/chopLifeLogo.svg" alt="Chopbet Logo" />
            <div class="close-btn">
              <div class="control-button" v-if="isLoaded">
                <router-link to="/deposit-funds" class="special-font change">
                  <ChopbetButton :casino="true">
                    {{ $t("deposit") }}</ChopbetButton
                  >
                </router-link>
              </div>
              <img
                class="close_circle"
                @click="closeSlideUp"
                src="/img/close-circle.svg"
                alt="Chopbet close menu"
              />
            </div>
          </div>
          <iframe
            allow="fullscreen"
            v-show="isLoaded"
            class="casino-iframe"
            v-bind:src="launchURL"
            allowfullscreen
            webkitallowfullscreen
          ></iframe>

          <div class="preview-game" v-show="!isLoading && !isLoaded">
            <div class="modal-img">
              <img
                v-if="previewGame"
                loading="lazy"
                v-bind:src="previewGame.image"
                alt="Avatar"
                data-toggle="modal"
                :data-target="'#casinoGames' + previewGame.game_id"
              />
              <div class="preview-name">
                <p>{{ previewGame.game_name }}</p>
              </div>
            </div>
            <div class="control-button">
              <ChopbetButton
                @click="getLaunchUrl(previewGame)"
                :loading="isLoading"
              >
                {{ $t("playGames") }}</ChopbetButton
              >
            </div>
          </div>
          <div class="slide-loading" v-show="isLoading">
            <p>{{ $t("loading") }}...</p>
            <div class="progress-track">
              <div class="level" :style="{ width: 70 + '%' }"></div>
            </div>
          </div>
        </div>
      </ChopbetSlideUp>
    </div>
  </div>
</template>

<style scoped src="./index.css"></style>

<script>
// const Slider = () => import("../../Slider.vue");
import providerServiceMap from "@/services/providerServiceMap";
import getProviderPayload from "@/utils/getProviderPayload";
import casino from "@/services/casino";
import ChopbetSlideUp from "@/components/ui/ChopbetSlideUp.vue";
import ChopbetButton from "../ui/ChopbetButton.vue";

export default {
  name: "PopularGames",
  data() {
    return {
      previewGame: {},
      isLoaded: false,
      isLoading: false,
      isSlideUpOpen: false,
      myProfile: this.getProfile(),
      aviatorGame: [
        {
          providerID: 7,
          game_id: "aviator",
          game_name: "Aviator",
          image: "/img/home/Aviator.jpg",
        },
        {
          providerID: 5,
          game_id: "JetX",
          game_name: "Jet X",
          image: "/img/home/JetX.jpg",
        },
        {
          providerID: 3,
          game_id: "1",
          game_name: "Comet",
          image: "/img/home/CometCrash.jpg",
        },
        {
          providerID: 4,
          game_id: "aviator",
          game_name: "Aviator",
          image: "/img/home/Aviator.jpg",
        },
        {
          providerID: 5,
          game_id: "1",
          game_name: "Comet",
          image: "/img/home/CometCrash.jpg",
        },
      ],
      homePageGame: [
        {
          game_id: "mines",
          provider_id: 1,
          game_name: "Monkey Bizniz",
          image: "/img/home/Monkey.webp",
        },
        {
          game_id: "hi-lo",
          provider_id: 1,
          game_name: "Rocket Man",
          image: "/img/home/Rocketman.webp",
        },
        {
          game_id: "hi-lo",
          provider_id: 1,
          game_name: "JetX3",
          image: "/img/home/JetX3.webp",
        },
        {
          game_id: "hi-lo",
          provider_id: 4,
          game_name: "PlinkoX",
          image: "/img/home/Plinko.webp",
        },
        {
          game_id: "hi-lo",
          provider_id: 1,
          game_name: "MineIsland",
          image: "/img/home/MineIsland.webp",
        },
        {
          game_id: "hi-lo",
          provider_id: 1,
          game_name: "Paperplane",
          image: "/img/home/Mandege.webp",
        },
        {
          game_id: "hi-lo",
          provider_id: 1,
          game_name: "Rugby Run",
          image: "/img/home/Rugby.webp",
        },
      ],
      casinoCategoryActive: {
        id: 1,
        name: "Crash",
      },
    };
  },
  components: {
    ChopbetSlideUp,
    ChopbetButton,
  },
  methods: {
    getKey: function (index) {
      return Math.random()
        .toString(10)
        .replace("0.", "games-id-" + index + "-");
    },
    closeModal() {
      const modals = document.getElementsByClassName("modal-window");
      for (let i = 0; i < modals.length; i++) {
        if (!modals[i].classList.contains("d-none")) {
          modals[i].classList.add("d-none");
          break;
        }
      }
    },
    setSportMenu: function (sport_id, sport_name) {
      this.sport_name = sport_name;
      this.$store.dispatch("setSportID", sport_id);
      this.$store.dispatch("setCurrentPage", sport_name);
      // console.log(this.$store.state.sport_id);
      this.$router.push({ name: "sport", params: {} });
    },
    openSlideUp(game) {
      this.previewGame = game;
      this.isSlideUpOpen = true;
    },
    closeSlideUp() {
      this.isLoaded = false;
      this.isSlideUpOpen = false;
    },
    getLaunchUrl(data) {
      if (!this.profile) {
        this.setError(this.$t("pleaseLoginProceed"));
        this.setValue("placeBet", 3);
        this.$router.push({ name: "login", params: {} });
        return;
      }
      this.isLoading = true;

      var providerId = data.provider_id;
      var gameId = data.game_id;
      var game_name = data.game_name;
      var accountID = parseInt(process.env.VUE_APP_CASINO_ACCOUNT_ID);

      var device_type = this.isMobile() ? "mobile" : "desktop";

      let payload = getProviderPayload(
        providerId,
        accountID,
        gameId,
        game_name,
        device_type
      );

      if (!payload) {
        this.isLoading = false;
        return;
      }
      return this.launchGame(payload);
    },

    getCasinoGames: function (activeCategory) {
      this.isLoading = true;
      this.casinoCategoryActive = activeCategory;

      var vm = this;

      var path =
        "/games?page=" +
        this.currentPage +
        "&per_page=" +
        this.perPage +
        "&category_id=" +
        activeCategory.id;

      casino.get(path).then((resp) => {
        vm.allgamesdata = resp.data.data;
        vm.allgames = vm.allgamesdata;

        // console.log(
        //   "allgames --> " + JSON.stringify(vm.allgames, undefined, 2)
        // );

        this.isLoading = false;

        window.scrollTo(0, 0);
      });
    },

    launchGame: function (payload) {
      var vm = this;
      var providerId = payload.providerId;
      var service = providerServiceMap[providerId];
      if (!service) {
        this.isLoading = false;
        return; // Exit the function if providerId is invalid
      }

      service
        .post("/launch/url", payload, {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((response) => {
          vm.launchURL = response.data.game_url;
          // console.log("LaunchURL", vm.launchURL);
          if (vm.launchType === "3") {
            window.open(this.launchURL);
          }
          // console.log("LaunchUrl", vm.launchURL);
          this.isLoaded = true; // Set isLoaded to true after getting the launch URL
          this.isLoading = false;
          return vm.launchURL;
        })
        .catch((error) => {
          this.isLoading = false;
          console.error(error);
          this.closeSlideUp();
          this.setError(`Invalid game id`);
        });
    },
  },
  computed: {
    profile: function () {
      return this.myProfile;
    },
  },
};
</script>
